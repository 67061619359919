import React from 'react'
import Banner from './Img/Untitled2.png'

const Gents = () => {
  return (
    <div className='gents'>

      <h1>One of the Best Beauty & Hair Family Salon in Lucknow </h1>
      <p>With an objective to bring international beauty inclination to the Indian market was established  . When it comes Hair Affair Studio  to beauty services, we understand the needs of 
        modern day Indian men and women alike. Thus, we offer the right blend of international services combined with the conventional allure so that you can make the most of your visit to our salon. We believe in keeping pace with the ever-evolving world of beauty services. You will find our facility the best for families, couples and individuals. No matter what your grooming requirement is, you can be assured to get the best right here with us.</p>


<img src={Banner} style={{width:'100%'}}/>


    </div>
  )
}

export default Gents