import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    {/* Apply rounded corners to the image */}
                    <img
                      src={d.img}
                      alt="..."
                      className="team-img"
                      style={{
                        width: "60%",
                        height: "200px",
                        borderRadius: "50%",
                      }}
                      // Adjust width and height values as needed
                    />
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
