import { Image } from "./image";
import React from "react";
import image1 from '../components/Img/new1.jpg';
import image2 from '../components/Img/new8.jpg';
import image3 from '../components/Img/new3.jpg';
import image4 from '../components/Img/new4.jpg';
import image5 from '../components/Img/new5.jpg';
import image6 from '../components/Img/new7.jpg';
import Video from '../components/Img/Video.mp4';

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
        </div>

        <div className="image-container">
          <img src={image1} alt="Gallery Image 1" />
          <img src={image2} alt="Gallery Image 2" />
          <img src={image3} alt="Gallery Image 3" />
          <img src={image4} alt="Gallery Image 4" />
          <img src={image5} alt="Gallery Image 5" />
          <img src={image6} alt="Gallery Image 6" />
        </div>

        {/* Add the video component */}
        <video width="100%" height="360" controls>
          <source src={Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};
