import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2 style={{ paddingTop: "60px", fontSize: "20px" }}>
            RECOMMENDED SERVICES
          </h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  <i className={d.icon}></i>
                  <img
                    src={d.img}
                    alt="..."
                    className="team-img"
                    style={{ width: "100%", height: "auto" }}
                  />
                  {/* <h3>{d.title}</h3> */}
                  <p style={{ textAlign: "left", fontSize: "16px",paddingTop:'20px' }}>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
