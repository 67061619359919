import React, { useState, useEffect } from 'react';
import Banner1 from './Img/baner-min.jpg';
import Banner2 from './Img/Untitled.png';
import Banner3 from './Img/banner3.png';

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [Banner1, Banner2, Banner3];

  useEffect(() => {
    // Function to handle automatic sliding
    const nextSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    };

    // Set up automatic sliding interval (e.g., every 3 seconds)
    const intervalId = setInterval(nextSlide, 3000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [images.length]); // Run useEffect when the number of images changes

  return (
    <div className='slide'>
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Banner ${index + 1}`}
          style={{ display: index === currentSlide ? 'block' : 'none' }}
        />
      ))}
    </div>
  );
};

export default Slider;
